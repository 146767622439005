import React from 'react'

import Link from 'next/link'

import { IoMdArrowRoundBack } from '@react-icons/all-files/io/IoMdArrowRoundBack'
import { RiQuestionnaireFill } from '@react-icons/all-files/ri/RiQuestionnaireFill'
import { useTranslation } from 'react-i18next'

import Logo from 'assets/logo.svg'

import useRouter from 'utils/hook/useRouter'

import { colors } from 'settings/theme'

import Button from 'components/ui/button'
import Container from 'components/ui/layout/container'

import { getUser } from '../../utils/user'

import { Layout, Wrapper } from './styles'

const NotFound = (): JSX.Element => {
    const { t } = useTranslation()
    const { push, routes } = useRouter()

    return (
        <Layout>
            <Container>
                <Wrapper>
                    <h1>
                        404
                        <RiQuestionnaireFill size={40} color={colors.primary} />
                    </h1>
                    <h3>{t('404.description')}</h3>
                    <Button onClick={() => push(getUser() ? routes.home : routes.login)} icon={<IoMdArrowRoundBack />}>{t('404.back_home')}</Button>
                </Wrapper>
            </Container>
            <Link href={routes.home}><Logo data-logo={true} /></Link>
        </Layout>
    )
}

export default NotFound
