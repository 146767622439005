import { keyframes } from '@emotion/css'
import styled from '@emotion/styled'

const wiggle = keyframes`
  0% {transform: rotate(5deg);}
  13% {transform: rotate(-3deg);}
  25% {transform: rotate(5deg);}
  38% {transform: rotate(-3deg);}
  50% {transform: rotate(5deg);}
  63% {transform: rotate(-3deg);}
  75% {transform: rotate(0deg);}
  100% {transform: rotate(5deg);}
`

export const Layout = styled.div`
  background-color: #fafafa;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 80px;
    position: relative;
    svg {
      top: -10px;
      right: -35px;
      position: absolute;
      transform: rotate(10deg);
      animation: ${wiggle} 2000ms ease-in-out infinite;
    }
  }
  
  [data-logo] {
    position: absolute;
    bottom: 20px;
    filter: grayscale(1);
    opacity: 0.7;
    cursor: pointer;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    margin-top: 30px;
  }
`
